import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap';

import Layout from '../components/Layout'
export const VirtualShowPageTemplate = ({
  title,
  description,
  video,
}) => (
  <div>
    <section id="video">
      <Row>
        <Col md={2} xs={12} className="sideBar" >
          <a href='/'><img src="/img/CDV.png" /></a>
        </Col>
        <Col md={10} xs={12} className="video">
          <div className="title"><h1>{title}</h1></div>
          <p>{description}</p>
          <video controls autoPlay>
            <source src={video}></source>
          </video>
        </Col>

      </Row>
    </section>
  </div>
)

VirtualShowPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  video: PropTypes.string,
}

const VirtualShowPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout currentSlug={frontmatter.title} title={frontmatter.title} description={frontmatter.description}>
      <VirtualShowPageTemplate
        title={frontmatter.title}
        description={frontmatter.description}
        video={frontmatter.video}
      />
    </Layout>
  )
}

VirtualShowPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default VirtualShowPage

export const pageQuery = graphql`
  query VirtualShowPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "virtual-show-page" } }) {
      frontmatter {
        title
        description
        video
      }
    }
  }
`